<template>
  <div v-if="$store.state.isLoading" class="container text-center">
    <!-- Loading spinner -->
    <div
      class="spinner-border mt-5"
      style="width: 4rem; height: 4rem"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div>
      <b> Loading... </b>
    </div>
  </div>
  <div v-else class="container">
    <div class="row align-items-center">
      <!-- Heading and Breadcrumb Column -->
      <div class="col-md-6 mt-4">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">
            Pricing Logs
            <!-- Costing Logs -->
          </h2>
          <span class="ms-3 fs-4 text-muted">|</span>
          <nav aria-label="breadcrumb" class="d-inline-block ms-3">
            <ol class="breadcrumb bg-transparent m-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/">
                  <i class="fas fa-home me-1"></i> Home
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <i class="fas fa-project-diagram me-1"></i>
                 Pricing Logs
                 <!-- Costing Logs -->
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div style="margin-top: 20px">
        <!-- Ag-Grid Component -->
        <ag-grid-vue
          style="height: 500px"
          class="ag-theme-quartz"
          :rowData="rowData"
          :defaultColDef="defaultColDef"
          :columnDefs="colDefs"
          :pagination="true"
          :rowSelection="'single'"
          @rowClicked="onRowClicked"
          @selectionChanged="onSelectionChanged"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";

export default {
  name: "PricingLogs",
  components: {
    AgGridVue,
  },
  data() {
    return {
      colDefs: [
        { headerName: "Task Id", field: "taskId" },
        { headerName: "Task Name", field: "taskName" },
        {
          headerName: "Bom Name",
          field: "result",
          cellRenderer: this.bomNameCellRenderer,
        },
        { headerName: "Completed Date and Time", field: "dateTime" },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: this.statusCellRenderer,
        },
      ],
      rowData: [],
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        autoSize: true,
        autoSizeColumns: true,
      },
      intervalId: null,
    };
  },
  mounted() {
    this.fetchLogs();
    this.intervalId = setInterval(this.fetchLogs, 60000); // Fetch logs every 1 minute

    document.addEventListener("click", this.handleBomFileClick);
  },
  beforeUnmount() {
    clearInterval(this.intervalId); // Clear interval when component is destroyed

    document.removeEventListener("click", this.handleBomFileClick);
  },
  methods: {
    statusCellRenderer(params) {
      const status = params.value.toLowerCase();
      let color = "";
      let statusText = "";

      switch (status) {
        case "success":
          color = "green";
          statusText = "Success";
          break;
        case "failure":
          color = "red";
          statusText = "Failure";
          break;
        case "started":
          color = "blue";
          statusText = "Pending";
          break;
        default:
          color = "white";
          statusText = "";
      }

      return `
        <div style="background-color: ${color}; color: white; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
          ${statusText}
        </div>
      `;
    },
    formatDateTime(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      return new Date(dateString).toLocaleString("en-US", options);
    },

    async fetchLogs() {
      this.$store.commit("setIsLoading", true);
      try {
        const response = await axios.get("/pricing/get-pricing-logs/");
        this.rowData = response.data.map((item) => {
          let result = {};

          // Check if item.result is defined and not null
          if (item.result && typeof item.result === "object") {
            result = item.result;
          } else if (item.result) {
            try {
              result = JSON.parse(item.result);
            } catch (e) {
              console.error("Error parsing result JSON:", e);
            }
          }

          // Check if result.bom_file_name is defined and not null
          const bomFileName =
            result && result.bom_file_name ? result.bom_file_name : "";

          return {
            taskId: item.task_id,
            taskName: item.task_name,
            result: bomFileName,
            dateTime: this.formatDateTime(item.date_done),
            status: item.status,
          };
        });
        console.log("Pricing Logs:", response.data);
      } catch (error) {
        console.error("Error fetching pricing logs:", error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },

    handleBomFileClick(event) {
      const target = event.target;
      if (target.classList.contains("bom-file-name")) {
        const result = JSON.parse(target.dataset.result);
        this.redirectToBomDetails(result);
      }
    },

    // bomNameCellRenderer(params) {
    //   const bomFileName = params.value;
    //   return `<div style="cursor: pointer; color: blue;" class="bom-file-name" data-result='${JSON.stringify(
    //     params.data
    //   )}'>${bomFileName}</div>`;
    // },

    bomNameCellRenderer(params) {
      const bomFileName = params.value;
      if (!bomFileName) {
        return "";
      }
      return `
    <div class="bom-file-name" 
         style="cursor: pointer; color: blue; text-decoration: underline; display: flex; align-items: center;" 
         data-result='${JSON.stringify(params.data)}'>
      <i class="fas fa-file-alt" style="margin-right: 5px;"></i>${bomFileName}
    </div>`;
    },

    redirectToBomDetails(result) {
      // Navigate to ParttPricing view and pass result as query parameter
      this.$router.push({
        name: "ParttPricing",
        query: { result: JSON.stringify(result) },
      });
    },

    // onRowClicked(event) {
    //   // Handle row click event
    // },
    // onSelectionChanged(event) {
    //   // Handle selection change event
    // },
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
