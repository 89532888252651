<template>
  <div v-if="$store.state.isLoading" class="container text-center">
    <div
      class="spinner-border mt-5"
      style="width: 4rem; height: 4rem"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div>
      <b> Loading... </b>
    </div>
  </div>
  <div v-else>
    <div
      v-if="lowestPriceDistributor && activeTab !== 'profile'"
      class="lowest-price-container"
    >
      <div
        class="alert d-flex align-items-center"
        style="color: black; background-color: white"
      >
        <i class="fas fa-award fa-2x me-3"></i>
        <div>
          <h4 class="alert-heading">Best Price Offered By</h4>
          <p>
            <strong>{{ lowestPriceDistributor.name }}</strong> with a price of
            <span class="badge bg-success">
              {{ formatCurrency(lowestPriceDistributor.price) }}
            </span>
            for quantity 1
          </p>
        </div>
        <div class="ms-auto">
          <a
            v-if="lowestPriceDistributor.datasheetUrl"
            class="btn btn-primary me-2"
            :href="lowestPriceDistributor.datasheetUrl"
            target="_blank"
            rel="noopener noreferrer"
            style="text-decoration: none; border-color: whitesmoke"
          >
            View Data Sheet
          </a>
          <a
            v-if="lowestPriceDistributor.productUrl"
            class="btn btn-success"
            :href="lowestPriceDistributor.productUrl"
            target="_blank"
            rel="noopener noreferrer"
            style="text-decoration: none; border-color: whitesmoke"
          >
            View Product
          </a>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mb-3 align-items-center">
      <div v-if="activeTab !== 'home'" class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="viewBestPrices"
          checked
          autocomplete="off"
          v-model="viewBestPrices"
          @change="toggleBestPrices"
        />
        <label
          class="form-check-label"
          for="viewBestPrices"
          style="margin-top: 1%"
        >
          View Best Prices
        </label>
      </div>
      <button @click="onBtExport" class="btn btn-primary ms-3">
        <i class="fas fa-download me-2"></i>Download CSV
      </button>
    </div>

    <ag-grid-vue
      ref="agGrid"
      style="height: 500px"
      class="ag-theme-quartz"
      :rowData="rowData"
      :defaultColDef="defaultColDef"
      :columnDefs="colDefs"
      :pagination="true"
    >
    </ag-grid-vue>

    <div
      class="modal fade"
      id="openRecommendationModal"
      tabindex="-1"
      aria-labelledby="openRecommendationModalLabel"
      aria-hidden="true"
      @click="closemodal()"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="openRecommendationModalLabel">
              Recommendation Details
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closemodal()"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="isLoading">
              <div
                class="spinner-border mt-5"
                style="width: 4rem; height: 4rem"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <div>
                <b> Loading... </b>
              </div>
            </div>
            <div v-else>
              <recommendation-details-table
                :recommendation_details="recommendation_details"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closemodal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridVue } from "ag-grid-vue3";
import RecommendationDetailsTable from "@/components/RecommendationDetailsTable.vue";
import axios from "axios";

export default {
  name: "PricingSearchTable",
  emits: ["recommendationDetails"],
  components: {
    AgGridVue,
    RecommendationDetailsTable,
  },
  props: {
    veplNumber_prices: {
      type: Array,
      default: () => [],
    },
    pricingdetails: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rowData: [],
      colDefs: [],
      recommendation_details: [],
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        autoSize: true,
        autoSizeColumns: true,
      },
      lowestPriceDistributor: null,
      test: false,
      isLoading: false,
      viewBestPrices: false,
      originalData: [],
    };
  },
  watch: {
    veplNumber_prices(newVal) {
      if (newVal && newVal.length) {
        this.initializeColumnDefinitions(newVal);
        this.rowData = newVal;
        this.originalData = newVal;
        this.calculateLowestPriceDistributor(newVal);
      }
    },
    pricingdetails(newVal) {
      if (newVal && newVal.length) {
        this.initializeColumnDefinitions(newVal);
        this.rowData = newVal;
        this.originalData = newVal;
        this.calculateLowestPriceDistributor(newVal);
      }
    },
  },
  mounted() {
    console.log(
      " mounted recommendation details ",
      this.recommendation_details
    );
  },
  created() {
    if (this.veplNumber_prices && this.veplNumber_prices.length) {
      this.initializeColumnDefinitions(this.veplNumber_prices);
      this.rowData = this.veplNumber_prices;
      this.originalData = this.veplNumber_prices;
      this.calculateLowestPriceDistributor(this.veplNumber_prices);
    } else if (this.pricingdetails && this.pricingdetails.length) {
      this.initializeColumnDefinitions(this.pricingdetails);
      this.rowData = this.pricingdetails;
      this.originalData = this.pricingdetails;
      this.calculateLowestPriceDistributor(this.pricingdetails);
    }
  },
  methods: {

    closemodal() {

      this.recommendation_details = []

    },
    initializeColumnDefinitions(data) {
      const hasVeplNumber = data.some((item) => item.vepl_number);

      const staticColumns = [
        {
          field: "Manufacturer Part Number",
          headerName: "Manufacturer Part No.",
        },
        {
          field: "distributor",
          headerName: "Distributor",
          cellRenderer: (params) => {
            return params.value
              ? `<span> ${params.value} </span>`
              : `<span> - </span>`;
          },
        },
        {
          field: "Manufacturer Name",
          headerName: "Manufacturer",
          cellRenderer: (params) => {
            return params.value
              ? `<span> ${params.value} </span>`
              : `<span> - </span>`;
          },
        },
        {
          field: "Description",
          headerName: "Description",
          cellRenderer: (params) => {
            return params.value
              ? `<span>${params.value}</span>`
              : `<span> - </span>`;
          },
        },
        {
          field: "Stock",
          headerName: "Stock",
          cellRenderer: (params) => {
            return params.value
              ? `<span>${params.value}</span>`
              : `<span> - </span>`;
          },
        },
        {
          field: "Currency",
          headerName: "Currency",
          cellRenderer: (params) => {
            return params.value
              ? `<span>${params.value}</span>`
              : `<span> - </span>`;
          },
        },
      ];

      if (hasVeplNumber) {
        staticColumns.unshift({
          field: "vepl_number",
          headerName: "VEPL Number",
        });
      }

      const dynamicColumns = this.extractPriceColumns(data);

      const urlColumns = [
        {
          field: "Datasheet Url",
          headerName: "View DataSheet",
          cellRenderer: (params) => {
            const openUrl = () => {
              window.open(params.value, "_blank");
            };

            const eyeIcon = document.createElement("i");
            eyeIcon.className = "fas fa-eye";
            eyeIcon.style.color = "blue";
            eyeIcon.style.cursor = "pointer";
            eyeIcon.addEventListener("click", openUrl);

            return eyeIcon;
          },
        },
        {
          field: "Product Url",
          headerName: "View Product",
          cellRenderer: (params) => {
            const openUrl = () => {
              window.open(params.value, "_blank");
            };

            const externalLinkIcon = document.createElement("i");
            externalLinkIcon.className = "fas fa-external-link-alt";
            externalLinkIcon.style.color = "green";
            externalLinkIcon.style.cursor = "pointer";
            externalLinkIcon.addEventListener("click", openUrl);

            return externalLinkIcon;
          },
        },
        {
          field: "",
          headerName: "View Recommendation",
          cellRenderer: (params) => {
            const viewRecommendation = () => {
              this.fetchRecommendation(params.data);
            };

            const recommendationIcon = document.createElement("i");
            recommendationIcon.className = "fas fa-share";
            recommendationIcon.style.color = "blue";
            recommendationIcon.style.cursor = "pointer";
            recommendationIcon.setAttribute("data-bs-toggle", "modal");
            recommendationIcon.setAttribute(
              "data-bs-target",
              "#openRecommendationModal"
            );
            recommendationIcon.addEventListener("click", viewRecommendation);

            return recommendationIcon;
          },
        },
      ];

      this.colDefs = [...staticColumns, ...dynamicColumns, ...urlColumns];
    },
    extractPriceColumns(data) {
      const priceFields = new Set();

      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key.startsWith("price(")) {
            priceFields.add(key);
          }
        });
      });

      const sortedPriceFields = Array.from(priceFields).sort((a, b) => {
        const getNumericValue = (field) => parseFloat(field.match(/\d+/)[0]);
        return getNumericValue(a) - getNumericValue(b);
      });

      const priceColumnDefs = sortedPriceFields.map((field) => ({
        field: field,
        headerName: field.replace(/price\((\d+)\)/, "Price ($1)"),
        type: "numericColumn",
        cellRenderer: (params) => {
          const currencySymbol = params.data["Currency Symbol"] || "";
          const value = params.value ? String(params.value) : "";

          if (value && !value.includes(currencySymbol)) {
            return `<span>${currencySymbol}${value}</span>`;
          } else if (params.value) {
            return `<span>${params.value}</span>`;
          } else {
            return `<span> - </span>`;
          }
        },
        valueFormatter: (params) => {
          const value = parseFloat(params.value);
          return isNaN(value) ? null : value.toFixed(2);
        },
      }));

      return priceColumnDefs;
    },
    calculateLowestPriceDistributor(data) {
      let lowestPrice = Infinity;
      let lowestPriceDistributor = null;

      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key.startsWith("price(1)")) {
            const price = parseFloat(item[key]);
            if (!isNaN(price) && price < lowestPrice) {
              lowestPrice = price;
              lowestPriceDistributor = {
                name: item.distributor,
                price: price,
                datasheetUrl: item["Datasheet Url"],
                productUrl: item["Product Url"],
              };
            }
          }
        });
      });

      this.lowestPriceDistributor = lowestPriceDistributor;
    },
    formatCurrency(value) {
      return value ? `$${value.toFixed(2)}` : "-";
    },
    onBtExport() {
      this.$refs.agGrid.api.exportDataAsCsv();
    },
    async fetchRecommendation(rowData) {
      this.isLoading = true;
      await axios
        .get("/pricing/get-recommendation-details", {
          params: rowData,
        })
        .then((response) => {
          console.log(" the fetched Recommendation :", response.data);
          this.test = true;
          console.log(this.test);
          this.isLoading = false;

          this.recommendation_details = response.data.final_json;

          console.log("the recomendationsssssss", this.recommendation_details);

          console.log(
            "Emitting recommendationDetails event with data:",
            this.recommendation_details
          );
          this.$emit("recommendation_details", response.data.final_json);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    toggleBestPrices() {
      if (this.viewBestPrices) {
        this.rowData = this.getBestPrices(this.originalData);
      } else {
        this.rowData = this.originalData;
      }
    },
    getBestPrices(data) {
      const bestPrices = {};

      data.forEach((item) => {
        const partNumber = item["Manufacturer Part Number"];
        const price = parseFloat(item["price(1)"]);

        if (
          !bestPrices[partNumber] ||
          (price && price < bestPrices[partNumber].price)
        ) {
          bestPrices[partNumber] = {
            ...item,
            price: price,
          };
        }
      });

      return Object.values(bestPrices);
    },
  },
};
</script>

<style scoped>
.lowest-price-container {
  margin-bottom: 20px;
}

.lowest-price-container .alert {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  border: 1px solid #bee5eb;
  border-radius: 5px;
  /* background-color: #d1ecf1; */
  color: #e4ecee;
}

.lowest-price-container .alert .fa-award {
  color: #28a745;
}

.lowest-price-container .alert-heading {
  margin: 0;
  font-size: 1.5rem;
}

.lowest-price-container p {
  margin: 0;
  font-size: 1.2rem;
}

.lowest-price-container .badge {
  font-size: 1rem;
}
</style>
